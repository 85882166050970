'use client';

import { useCallback, useEffect, useState } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { CLOSE_CONFIGURATOR } from '@/constants/configurator';
import { CUSTOMISE_SEARCH_PARAM } from '@/constants/general';
import useDialog from '@/hooks/useDialog';
import Dialog from '@mui/material/Dialog';
import toggleChat from '@/utils/toggleChat';

declare global {
  interface Window {
    LiveChatWidget: any;
  }
}

interface OpenConfiguratorProps {
  id: string;
  productId: string;
  Component: ({ onClick }: { onClick: () => void }) => React.ReactNode;
  keepMounted?: boolean;
  openAutomatically?: boolean;
}

export default function OpenConfigurator({
  id,
  productId,
  Component,
  keepMounted = false,
  openAutomatically = false,
}: OpenConfiguratorProps) {
  const { open, handleClick, handleClose, setOpen } = useDialog();
  const router = useRouter();
  const searchParams = useSearchParams();
  const [hasBeenOpened, setHasBeenOpened] = useState(keepMounted);

  const handleDialogOpen = useCallback(() => {
    setHasBeenOpened(true);
    handleClick();
    toggleChat(false);
  }, [handleClick]);

  const handleDialogClose = useCallback(() => {
    handleClose();
    toggleChat(true);
  }, [handleClose]);

  useEffect(() => {
    if (!open) return;

    function closeDialog({
      data,
      origin,
    }: {
      data: {
        id: string;
      } | null;
      origin: string;
    }) {
      if (data?.id === CLOSE_CONFIGURATOR && origin === window.location.origin) {
        handleDialogClose();
        router.refresh();
      }
    }

    window.addEventListener('message', closeDialog);

    return () => {
      window.removeEventListener('message', closeDialog);
    };
  }, [open, router, handleClose, handleDialogClose]);

  const openManually = searchParams.get(CUSTOMISE_SEARCH_PARAM) === 'true';

  useEffect(() => {
    if (openAutomatically) {
      setOpen(openManually);
    }
  }, [openAutomatically, openManually, setOpen]);

  if (!id) return null;

  return (
    <>
      <Component onClick={handleDialogOpen} />

      <Dialog
        open={open}
        onClose={handleDialogClose}
        fullScreen
        fullWidth
        disableEscapeKeyDown
        keepMounted={hasBeenOpened}
      >
        <iframe
          src={`/configure/${id}?productId=${productId}`}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
            overflow: 'hidden',
          }}
        />
      </Dialog>
    </>
  );
}
