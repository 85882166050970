import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export default function EmptyMessage({ text }: { text: string }) {
  return (
    <Card
      sx={{
        p: 2,
        bgcolor: '#00000005',
        border: '2px dashed',
        borderColor: 'divider',
        borderRadius: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          color: 'text.secondary',
        }}
      >
        {text}
      </Typography>
    </Card>
  );
}
