'use client';

import { useCallback, useMemo, useState } from 'react';
import { LineItem as ILineItem } from '@/types/general';
import { useRouter } from 'next/navigation';
import getCartDetails from '@/utils/cart/getCartDetails';
import mutate from '@/lib/sanity/mutate';
import useAuth from '@/hooks/useAuth';
import useNotification from '@/hooks/useNotification';

interface IUseLineItems {
  items?: ILineItem[];
  onClose?: () => void;
  quoteId?: string;
}

export default function useLineItems({ items = [], onClose = () => {}, quoteId }: IUseLineItems) {
  const router = useRouter();
  const data = useMemo(() => getCartDetails({ items }), [items]);
  const { userId } = useAuth();
  const [isLoading, setIsLoading] = useState<string | null>(null);
  const { showNotification } = useNotification();

  const isEmpty = !items.length;

  const headers = [
    {
      label: 'Product',
      sx: {
        width: {
          xs: '100%',
          md: '50%',
        },
      },
    },
    {
      label: 'Quantity',
      sx: {
        width: '10%',
        display: {
          xs: 'none',
          md: 'table-cell',
        },
      },
    },
    {
      label: 'Unit Price (Inc GST)',
      sx: {
        width: '15%',
        display: {
          xs: 'none',
          md: 'table-cell',
        },
      },
    },
    ...(data?.hasDiscount
      ? [
          {
            label: 'Discount',
            sx: {
              width: '10%',
              display: {
                xs: 'none',
                md: 'table-cell',
              },
            },
          },
          {
            label: 'Total',
            sx: {
              width: '10%',
              display: {
                xs: 'none',
                md: 'table-cell',
              },
            },
          },
        ]
      : [
          {
            label: 'Total',
            sx: {
              width: '15%',
              display: {
                xs: 'none',
                md: 'table-cell',
              },
            },
          },
        ]),
    {
      label: '',
      sx: {
        width: '5%',
        display: {
          xs: 'none',
          md: 'table-cell',
        },
      },
    },
  ];

  const handleClose = useCallback(
    (message?: string) => {
      setIsLoading(null);
      onClose();
      router.refresh();

      showNotification({
        message: message || 'Operation completed successfully.',
        severity: 'success',
      });
    },
    [onClose, router, showNotification],
  );

  const handleEmptyCart = useCallback(async () => {
    setIsLoading('emptyCart');

    return mutate([
      {
        patch: { id: userId as string, set: { cart: [] } },
      },
    ]).then(() => handleClose('Cart emptied successfully.'));
  }, [userId, handleClose]);

  const handleEmptyQuote = useCallback(async () => {
    setIsLoading('emptyQuote');

    return mutate([
      {
        patch: { id: quoteId as string, set: { lineItems: [] } },
      },
    ]).then(() => handleClose('Quote emptied successfully.'));
  }, [quoteId, handleClose]);

  const handleDeleteQuote = useCallback(async () => {
    setIsLoading('deleteQuote');

    return mutate([
      {
        delete: { id: quoteId as string },
      },
    ]).then(() => handleClose('Quote deleted successfully.'));
  }, [quoteId, handleClose]);

  const handleMoveToCart = useCallback(async () => {
    setIsLoading('moveToCart');

    return mutate([
      {
        patch: { id: quoteId as string, set: { lineItems: [] } },
      },
      {
        patch: {
          id: userId as string,
          setIfMissing: { cart: [] },
          insert: {
            after: 'cart[-1]',
            items: items?.map((item) => ({
              _type: 'lineItem',
              product: {
                _ref: item.id,
                _type: 'reference',
              },
              quantity: item.quantity,
            })),
          },
        },
      },
    ]).then(() => handleClose('Quote moved to cart successfully.'));
  }, [quoteId, userId, items, handleClose]);

  return {
    handleEmptyCart,
    handleEmptyQuote,
    handleDeleteQuote,
    handleMoveToCart,
    handleClose,
    setIsLoading,
    isLoading,
    headers,
    isEmpty,
    data,
  };
}
