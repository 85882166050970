import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import money from '@/utils/money';
import useSWR from 'swr';
import get from '@/lib/sanity/get';
import { ShippingFee } from '@/utils/cart/calculateShippingCost';

type Details = { label: string; value: string | number };

const zero = money(0);

export function useShippingFees() {
  const { data = [] }: { data: ShippingFee[] } = useSWR('shipping-fees', () =>
    get({
      query: `*[_id == 'generalSettings'][0].shipping.shippingFees`,
      cache: true,
      tags: ['generalSettings'],
    }),
  );

  return {
    shippingFees: data,
  };
}

export default function CartSummary({ details }: { details: Details[] }) {
  return (
    <Table>
      <TableBody
        sx={{
          '& tr:last-child': {
            bgcolor: 'divider',
          },
        }}
      >
        {details?.map(({ label, value }, index) => {
          if (value === zero) {
            return null;
          }

          return (
            <TableRow key={index}>
              <TableCell>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                  {label}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='body2'>{value}</Typography>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}
