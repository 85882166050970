import { TAX_RATE } from '@/constants/general';
import { LineItem } from '@/types/general';
import money from '@/utils/money';
import calculateShippingCost, { ShippingFee } from '@/utils/cart/calculateShippingCost';

type Details = { label: string; value: string | number };

interface IGetCartDetails {
  items: LineItem[];
  shippingFees?: ShippingFee[];
  country?: string | null;
  postalCode?: string | null;
}

export default function getCartDetails({
  items,
  shippingFees = [],
  country = null,
  postalCode = null,
}: IGetCartDetails) {
  const defaultValues = {
    quantity: 0,
    subtotal: 0,
    discount: 0,
    total: 0,
    shipping: 0,
    setupFee: 0,
  };

  if (!items?.length) {
    return {
      details: [],
      hasDiscount: false,
      values: defaultValues,
    };
  }

  // Calculate initial stats with prices including GST
  const stats = items?.reduce((acc, item) => {
    acc.quantity += item.quantity;
    acc.subtotal += item.price * item.quantity; // Keep subtotal with GST
    acc.discount += item.discount;
    acc.total += item.total;
    acc.setupFee += item.setupFee;
    return acc;
  }, defaultValues);

  // Calculate shipping without GST
  const shipping =
    !country || !postalCode
      ? 0
      : calculateShippingCost({
          price: stats.total,
          shippingFees,
          country,
          postalCode,
        });

  // Calculate GST (Australian tax rate)
  const gstRate = TAX_RATE - 1;

  // rounded to 2 decimal places
  const rounded = (value: number) => Math.round(value * 100) / 100;

  // Calculate values excluding GST
  const subtotalExGST = stats.subtotal / TAX_RATE;
  const discountExGST = stats.discount / TAX_RATE;
  const shippingExGST = shipping;
  const setupFeeExGST = stats.setupFee;

  // Calculate GST for each component
  const subtotalGST = subtotalExGST * gstRate;
  const discountGST = discountExGST * gstRate;
  const shippingGST = shippingExGST * gstRate;
  const setupFeeGST = setupFeeExGST * gstRate;

  // Calculate total GST
  const totalGST = subtotalGST - discountGST + shippingGST + setupFeeGST;

  // Calculate final values
  const values = {
    quantity: stats.quantity,
    subtotal: rounded(subtotalExGST), // Keep original subtotal with GST
    discount: rounded(discountExGST), // Keep original discount with GST
    shipping: rounded(shippingExGST),
    setupFee: rounded(setupFeeExGST),
    gst: rounded(totalGST),
    total: rounded((subtotalExGST - discountExGST + shippingExGST + setupFeeExGST) * TAX_RATE),
  };

  const hasDiscount = values.discount > 0;
  const shippingLabel = [country, postalCode].filter(Boolean).join(', ');

  const details = [
    { label: 'Total Items', value: values.quantity },
    { label: 'Subtotal (Ex GST)', value: money(values.subtotal) },
    hasDiscount
      ? {
          label: 'Discount (Ex GST)',
          value: money(values.discount * -1),
        }
      : null,
    {
      label: `Shipping${shippingLabel ? ` (${shippingLabel})` : ''} (ex GST)`,
      value: money(values.shipping),
    },
    {
      label: 'Setup Fee (ex GST)',
      value: money(values.setupFee),
    },
    {
      label: 'GST (10%)',
      value: money(values.gst),
    },
    { label: 'Total (inc GST)', value: money(values.total) },
  ].filter(Boolean) as Details[];

  return { details, hasDiscount, values };
}
