'use client';

import { useState } from 'react';
import { LineItem as ILineItem } from '@/types/general';
import React from 'react';
import Image from '@/components/Image';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import money from '@/utils/money';
import Actions from './Actions';
import Quantity from './Quantity';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

type Row = { label: string; value: React.ReactNode };

export default function LineItem({
  id,
  name = 'Unnamed Item',
  description = '',
  price = 0,
  image = '',
  quantity = 0,
  productId,
  custom = false,
  discount = 0,
  discountPercentage = 0,
  total = 0,
  hasDiscount = false,
  setupFee = 0,
  type = 'cart',
  quoteId,
}: ILineItem) {
  const [open, setOpen] = useState(false);

  const rows = [
    {
      label: 'Quantity',
      value: <Quantity id={id as string} quantity={quantity} type={type} quoteId={quoteId} />,
    },
    { label: 'Unit Price (Inc GST)', value: money(price) },
    hasDiscount && {
      label: 'Discount',
      value: `${money(discount)} (${discountPercentage}%)`,
    },
    { label: 'Total', value: money(total) },
    {
      label: 'Actions',
      value: (
        <Actions
          custom={custom}
          id={id as string}
          quoteId={quoteId}
          type={type}
          productId={productId}
        />
      ),
    },
  ].filter(Boolean) as Row[];

  return (
    <TableRow>
      <TableCell>
        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={2}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          justifyContent='flex-start'
        >
          <Image
            src={image}
            alt={name}
            aspectRatio='1/1'
            objectFit='contain'
            sx={{
              width: {
                xs: 100,
                md: 180,
              },
              height: {
                xs: 100,
                md: 180,
              },
            }}
            sizes='(max-width: 768px) 120px, 200px'
          />
          <Stack direction='column' spacing={0.5} sx={{ width: '100%' }}>
            <Typography variant='body1'>{name}</Typography>

            {setupFee > 0 && (
              <Typography variant='caption' color='error.light'>
                Setup fee: {money(setupFee)}
              </Typography>
            )}

            <div>
              <Button
                onClick={() => setOpen(!open)}
                size='small'
                color='inherit'
                variant='text'
                startIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                sx={{
                  color: 'text.secondary',
                  fontSize: '0.8rem',
                  textTransform: 'none',
                  py: 0,
                  borderRadius: '10px',
                }}
              >
                {open ? 'See less details' : 'See more details'}
              </Button>
            </div>

            <Collapse in={open}>
              <Typography variant='caption' color='text.secondary'>
                {description || 'No description'}
              </Typography>
            </Collapse>

            <div>
              <Table
                sx={{
                  '& td': {
                    border: 'none',
                    p: 0,
                    py: 0.5,
                    width: '100%',
                  },
                  display: { xs: 'block', md: 'none' },
                }}
              >
                <TableBody>
                  {rows.map(({ value, label }, index) => (
                    <TableRow key={index}>
                      <TableCell>{label}</TableCell>
                      <TableCell sx={{ textAlign: 'right' }}>{value}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </Stack>
        </Stack>
      </TableCell>

      {rows.map(({ value }, index) => (
        <TableCell key={index} sx={{ display: { xs: 'none', md: 'table-cell' } }}>
          {value}
        </TableCell>
      ))}
    </TableRow>
  );
}
