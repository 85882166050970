'use client';

import { useCallback, useTransition } from 'react';
import { useRouter } from 'next/navigation';
import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OpenConfigurator from '@/components/OpenConfigurator';
import mutate from '@/lib/sanity/mutate';
import useAuth from '@/hooks/useAuth';
import useNotification from '@/hooks/useNotification';

interface IActions {
  custom: boolean;
  quoteId?: string;
  id: string;
  type: 'cart' | 'quote' | 'order';
  productId: string;
}

export default function Actions({ custom, quoteId, id, type, productId }: IActions) {
  const [isPendingRemove, startTransitionRemove] = useTransition();
  const router = useRouter();
  const { userId } = useAuth();
  const { showNotification } = useNotification();

  const handleRemove = useCallback(async () => {
    startTransitionRemove(async () => {
      const patch =
        type === 'cart'
          ? {
              id: userId as string,
              unset: [`cart[product._ref == "${id}"]`],
            }
          : {
              id: quoteId as string,
              unset: [`lineItems[product._ref == "${id}"]`],
            };

      await mutate([
        {
          patch,
        },
      ]).then(() => {
        router.refresh();

        showNotification({
          message: `Item removed from ${type}`,
          severity: 'success',
        });
      });

      return;
    });
  }, [id, quoteId, router, type, userId, showNotification]);

  return (
    <Stack
      direction='row'
      spacing={1}
      sx={{
        justifyContent: 'flex-end',
        color: 'text.secondary',
      }}
    >
      {custom && (
        <OpenConfigurator
          keepMounted={false}
          id={id as string}
          productId={productId}
          Component={({ onClick }) => (
            <IconButton onClick={onClick} size='small' color='inherit' disabled={isPendingRemove}>
              <EditIcon fontSize='small' />
            </IconButton>
          )}
        />
      )}

      {['cart', 'quote'].includes(type) && (
        <IconButton
          onClick={handleRemove}
          size='small'
          color='inherit'
          sx={{
            color: 'text.secondary',
          }}
          loading={isPendingRemove}
        >
          <DeleteIcon fontSize='small' />
        </IconButton>
      )}
    </Stack>
  );
}
