'use client';

import { LineItem as ILineItem } from '@/types/general';
import LineItem from './LineItem';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import EmptyMessage from '@/components/EmptyMessage';
import Button from '@mui/material/Button';
import Link from 'next/link';
import CartSummary from '@/components/CartSummary';
import useLineItems from './useLineItems';
import MoveToQuote from './MoveToQuote';

interface ILineItems {
  items?: ILineItem[];
  emptyMessage?: string;
  onClose?: () => void;
  actions?: {
    emptyCart?: boolean;
    emptyQuote?: boolean;
    deleteQuote?: boolean;
    moveToQuote?: boolean;
    moveToCart?: boolean;
    summary?: boolean;
    proceedToCheckout?: boolean;
    continueShopping?: boolean;
  };
  quoteId?: string;
  type?: 'cart' | 'quote' | 'order';
}

export default function LineItems({
  items = [],
  emptyMessage = 'This cart has no items.',
  onClose = () => {},
  actions = {},
  quoteId,
  type = 'cart',
}: ILineItems) {
  const {
    handleEmptyCart,
    handleEmptyQuote,
    handleDeleteQuote,
    handleMoveToCart,
    setIsLoading,
    handleClose,
    isLoading,
    headers,
    isEmpty,
    data,
  } = useLineItems({ items, onClose, quoteId });

  const hasActions = Object.values(actions).some((value) => value);

  return (
    <div>
      {!isEmpty && (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '& th': {
                  borderBottom: '1px solid black',
                },
              }}
            >
              {headers.map(({ label, sx }, index) => (
                <TableCell key={index} sx={sx}>
                  <Typography variant='caption' sx={{ fontWeight: 'bold' }}>
                    {label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {items.map((props, index) => (
              <LineItem
                key={index}
                {...props}
                hasDiscount={data?.hasDiscount}
                quoteId={quoteId}
                type={type}
              />
            ))}
          </TableBody>
        </Table>
      )}

      {isEmpty && <EmptyMessage text={emptyMessage} />}

      {hasActions && (
        <Stack
          direction={{
            xs: 'column',
            md: 'row',
          }}
          spacing={1}
          sx={{
            mt: 2,
            borderBottom: '1px solid',
            borderColor: 'divider',
            pb: 2,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={1}
            sx={{
              width: {
                xs: '100%',
                md: 'auto',
              },
            }}
          >
            {actions.emptyQuote && (
              <Button
                variant='outlined'
                color='error'
                onClick={handleEmptyQuote}
                size='small'
                fullWidth
                loading={isLoading === 'emptyQuote'}
                disabled={!!isLoading}
              >
                Empty Quote
              </Button>
            )}

            {actions.emptyCart && (
              <Button
                variant='outlined'
                color='error'
                onClick={handleEmptyCart}
                size='small'
                fullWidth
                loading={isLoading === 'emptyCart'}
                disabled={!!isLoading}
              >
                Empty Cart
              </Button>
            )}

            {actions.deleteQuote && (
              <Button
                variant='outlined'
                color='error'
                fullWidth
                onClick={handleDeleteQuote}
                size='small'
                loading={isLoading === 'deleteQuote'}
                disabled={!!isLoading}
              >
                Delete Quote
              </Button>
            )}
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              md: 'row',
            }}
            spacing={1}
            sx={{
              width: {
                xs: '100%',
                md: 'fit-content',
              },
            }}
          >
            {actions.moveToQuote && (
              <MoveToQuote
                itemsToMove={items}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onClose={handleClose}
              />
            )}

            {actions.moveToCart && (
              <Button
                variant='outlined'
                onClick={handleMoveToCart}
                size='small'
                fullWidth
                loading={isLoading === 'moveToCart'}
                disabled={!!isLoading}
              >
                Move to Cart
              </Button>
            )}

            {actions.continueShopping && (
              <Button variant='outlined' size='small' onClick={onClose} fullWidth>
                Continue Shopping
              </Button>
            )}
          </Stack>
        </Stack>
      )}

      {actions.summary && (
        <Stack
          direction='column'
          sx={{ maxWidth: { xs: '100%', md: 380 }, ml: 'auto', py: 2 }}
          spacing={1.5}
        >
          <div>
            <Typography variant='h4' className='title-underlined'>
              Summary
            </Typography>
          </div>

          <CartSummary details={data?.details || []} />

          {actions.proceedToCheckout && (
            <Button
              variant='contained'
              size='large'
              component={Link}
              href='/checkout'
              onClick={onClose}
            >
              Proceed to Checkout
            </Button>
          )}
        </Stack>
      )}
    </div>
  );
}
