'use client';

import { useCallback, useTransition, useState } from 'react';
import { QUANTITY_MIN, QUANTITY_MAX } from '@/constants/general';
import { useRouter } from 'next/navigation';
import React from 'react';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import mutate from '@/lib/sanity/mutate';
import useAuth from '@/hooks/useAuth';
import TextField from '@mui/material/TextField';
import useNotification from '@/hooks/useNotification';

interface IQuantity {
  quoteId?: string;
  id: string;
  quantity: number;
  type: 'cart' | 'quote' | 'order';
}

export default function Quantity({ quoteId, id, quantity = 0, type }: IQuantity) {
  const { userId } = useAuth();
  const [isPendingAdd, startTransitionAdd] = useTransition();
  const [isPendingMinus, startTransitionMinus] = useTransition();
  const [isPendingInput, startTransitionInput] = useTransition();
  const [localQuantity, setLocalQuantity] = useState(quantity);
  const { showNotification } = useNotification();
  const router = useRouter();

  const isAnyPending = isPendingAdd || isPendingMinus || isPendingInput;

  const handleQuantityChange = useCallback(
    async (newQuantity: number) => {
      const clampedQuantity = Math.min(Math.max(newQuantity, QUANTITY_MIN), QUANTITY_MAX);

      const patch =
        type === 'cart'
          ? {
              id: userId as string,
              set: {
                [`cart[product._ref == "${id}"].quantity`]: clampedQuantity,
              },
            }
          : {
              id: quoteId as string,
              set: {
                [`lineItems[product._ref == "${id}"].quantity`]: clampedQuantity,
              },
            };

      return mutate([
        {
          patch,
        },
      ]).then(() => {
        router.refresh();

        return showNotification({
          message: 'Quantity updated',
        });
      });
    },
    [id, quoteId, router, type, userId, showNotification],
  );

  const handleAdd = useCallback(async () => {
    startTransitionAdd(async () => {
      const newQuantity = localQuantity + 1;
      setLocalQuantity(Math.min(newQuantity, QUANTITY_MAX));
      await handleQuantityChange(newQuantity);
    });
  }, [handleQuantityChange, localQuantity]);

  const handleMinus = useCallback(async () => {
    startTransitionMinus(async () => {
      const newQuantity = localQuantity - 1;
      setLocalQuantity(Math.max(newQuantity, QUANTITY_MIN));
      await handleQuantityChange(newQuantity);
    });
  }, [handleQuantityChange, localQuantity]);

  const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(e.target.value) || QUANTITY_MIN;
    setLocalQuantity(Math.min(Math.max(value, QUANTITY_MIN), QUANTITY_MAX));
  }, []);

  const handleInputBlur = useCallback(() => {
    if (localQuantity === quantity) return;

    startTransitionInput(async () => {
      await handleQuantityChange(localQuantity);
    });
  }, [handleQuantityChange, localQuantity, quantity]);

  if (type === 'order') return <>{quantity}</>;

  return (
    <Stack direction='row' spacing={1} sx={{ alignItems: 'center' }}>
      <IconButton
        onClick={handleMinus}
        size='small'
        color='inherit'
        disabled={localQuantity <= QUANTITY_MIN || isAnyPending}
        loading={isPendingMinus}
      >
        <RemoveIcon fontSize='small' />
      </IconButton>
      <TextField
        value={localQuantity}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        size='small'
        type='number'
        disabled={isAnyPending}
        sx={{
          width: 40,
          '& input': {
            textAlign: 'center',
          },
          '& input[type=number]::-webkit-inner-spin-button, & input[type=number]::-webkit-outer-spin-button':
            {
              WebkitAppearance: 'none',
              margin: 0,
            },
          '& input[type=number]': {
            MozAppearance: 'textfield',
          },
        }}
        slotProps={{
          input: {
            inputProps: {
              min: QUANTITY_MIN,
              max: QUANTITY_MAX,
            },
          },
        }}
      />
      <IconButton
        onClick={handleAdd}
        size='small'
        color='inherit'
        disabled={localQuantity >= QUANTITY_MAX || isAnyPending}
        loading={isPendingAdd}
      >
        <AddIcon fontSize='small' />
      </IconButton>
    </Stack>
  );
}
