'use client';

import { useCallback } from 'react';
import { LineItem as ILineItem } from '@/types/general';
import Quotes from '@/components/Quotes';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import mutate from '@/lib/sanity/mutate';
import AddQuote from '@/components/AddQuote';
import useQuote from '@/hooks/useQuote';

interface IMoveToQuote {
  itemsToMove: ILineItem[];
  isLoading: string | null;
  setIsLoading: (value: string | null) => void;
  onClose: (message?: string) => void;
}

export default function MoveToQuote({
  itemsToMove,
  isLoading,
  setIsLoading,
  onClose,
}: IMoveToQuote) {
  const {
    userId,
    items,
    getItems,
    selectedValue,
    setSelectedValue,
    open,
    handleClose,
    CloseButton,
    handleClickAddToQuote,
  } = useQuote();

  const handleMoveToQuote = useCallback(async () => {
    setIsLoading('moveToQuote');
    return mutate([
      {
        patch: {
          id: selectedValue as string,
          setIfMissing: { lineItems: [] },
          insert: {
            after: 'lineItems[-1]',
            items: itemsToMove?.map((item) => ({
              _type: 'lineItem',
              product: {
                _ref: item.id,
                _type: 'reference',
              },
              quantity: item.quantity,
            })),
          },
        },
      },
      {
        patch: { id: userId as string, set: { cart: [] } },
      },
    ]).then(async () => {
      handleClose();
      await getItems();
      onClose('Items moved to quote');
    });
  }, [selectedValue, handleClose, itemsToMove, setIsLoading, onClose, userId, getItems]);

  return (
    <>
      <Button
        variant='outlined'
        onClick={handleClickAddToQuote}
        loading={isLoading === 'moveToQuote'}
        disabled={!!isLoading}
        size='small'
        fullWidth
      >
        Move to Quote
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='sm'>
        <DialogTitle
          variant='h5'
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1.5 }}
        >
          Move to Quote <CloseButton />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>This will move all items to the quote.</DialogContentText>

          <Quotes items={items} selectedValue={selectedValue} setSelectedValue={setSelectedValue} />

          <AddQuote
            getItems={getItems}
            setSelectedValue={setSelectedValue}
            handleAddToQuote={handleMoveToQuote}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant='text'>
            Cancel
          </Button>

          <Button
            onClick={handleMoveToQuote}
            color='primary'
            variant='contained'
            disabled={!selectedValue}
            loading={isLoading === 'moveToQuote'}
          >
            Move to Quote
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
